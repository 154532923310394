<template>
  <div>
    <div class="container">
      <!-- 背景 -->
      <img class="back-ground-image" src="@/assets/image/idx-jiagouTu.png" alt="" />
      <!-- 顶部 -->
      <div class="header">数字东泰</div>
      <!-- 返回 刷新 -->
      <rightNav></rightNav>
      <!-- 时间 -->
      <div class="time-box">
        <Date></Date>
      </div>
      <div class="renkou-top">
          <div class="top">
              东泰社区管理架构
          </div>
          <div class="bottom">
              <img src="@/assets/image/jiagouTu.png" alt="">
          </div>
        <!-- <div class="renkou-top-L">
        </div>
        <div class="renkou-top-M">
            <div class="rekou_top">
                东泰社区编外工作人员量化考核流程
            </div>
          <div class="img_LCT">
              <img src="@/assets/image/kaoheLCT.png" alt="">
          </div>
        </div>
        <div class="renkou-top-R">
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Demo from "@/views/judicialMediation/components/demo.vue";
import Date from "@/views/commonComponents/date.vue";
import XinjianLy from "@/views/judicialMediation/components/xinjianLy.vue";
import TiaojieHz from "@/views/judicialMediation/components/tiaojieHz.vue";
import NumTb from "@/views/judicialMediation/components/numTb.vue";
import EventsPlace from "@/views/judicialMediation/components/eventsPlace.vue";
import rightNav from "@/views/commonComponents/rightNav.vue";
export default {
  components: {
    Demo,
    Date,
    EventsPlace,
    XinjianLy,
    TiaojieHz,
    NumTb,
    rightNav,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
  mounted() {},
};
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  height: 100%;
  .back-ground-image {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
  }
  // 顶部
  .header {
    width: 100%;
    height: 10vh;
    line-height: 10vh;
    letter-spacing: 4vw;
    padding-left: 5vw;
    // background: linear-gradient(to top, #c0d9ff, #fff);
    background: linear-gradient(to top, #c0d9ff, #fff);
    -webkit-background-clip: text;
    color: transparent;
    font-size: 3vw;
    text-align: center;
    font-family: Microsoft YaHei;
    font-weight: bold;
    // color: #FFFFFF;
    text-shadow: 0px 10px 7px rgba(0, 0, 0, 0.17);
  }
  .time-box {
    width: 25vw;
    height: 7vh;
    // border: 1px solid teal;
    position: fixed;
    top: 7vh;
    right: 3vw;
    z-index: 99;
  }
  // top
  .renkou-top {
    width: 100%;
    // padding: 0 1vw;
    // height: 100%;
    // border: 1px solid tomato;
    // background: red;
    font-size: 1.1vw;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #FFD901;
    margin-top: 7.3vh;
    text-align: center;
    .top{
        height: 5vh;
        // background: red;
    }
    .bottom{
        height: 75vh;
        // overflow-x:hidden;
        overflow-y: scroll;
        padding: 0 1vw;
        img{
            width: 100%;
            // height: 100%;
        }
    }
  }
}
</style>
